<template>
  <div class="dialog_block">
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="isClose"
      :visible.sync="show"
    >
      <el-form
        ref="comForm"
        :model="form"
        style="padding: 0 0.5rem"
        label-width="1.5rem"
      >
        <template v-for="item in list">
          <el-form-item
            :key="item.prop"
            :label="item.label"
            :prop="item.prop || ''"
            :required="item.required || false"
            :rules="item.rules || null"
            :size="item.size || ''"
            class="form-item-inp"
          >
            <el-input
              v-if="item.type === 'text' && edit !== 'view'"
              :disabled="item.disabled"
              v-model="form[item.prop]"
            ></el-input>
            <el-input
              v-else-if="item.type === 'number' && edit !== 'view'"
              :disabled="item.disabled"
              v-model="form[item.prop]"
            ></el-input>
            <el-input
              v-else-if="item.type === 'password' && edit !== 'view'"
              type="password"
              :disabled="item.disabled"
              v-model="form[item.prop]"
            ></el-input>
            <el-select
              v-else-if="item.type === 'select' && edit !== 'view'"
              :disabled="item.disabled"
              style="width: 100%"
              :multiple="item.multiple || false"
              v-model="form[item.prop]"
              placeholder="请选择"
            >
              <el-option
                v-for="val in item.options"
                :key="val.value"
                :label="val.label"
                :value="val.value"
              >
              </el-option>
            </el-select>
            <el-upload
              v-else-if="item.type === 'upload' && edit !== 'view'"
              :headers="{ token: $store.state.userInfo.token }"
              class="avatar-uploader"
              :action="constant.UPLOAD_URL + '/file/upload'"
              :show-file-list="false"
              :on-success="(v) => handleAvatarSuccess(v, item.prop)"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="form[item.prop]"
                :src="form[item.prop]"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-date-picker
              v-else-if="item.type === 'date' && edit !== 'view'"
              style="width: 100%"
              v-model="form[item.prop]"
              :disabled="item.disabled"
              :picker-options="pickerOptions"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
            <el-radio-group
              v-else-if="item.type === 'radio' && edit !== 'view'"
              :disabled="item.disabled"
              v-model="form[item.prop]"
              @change="(val) => changeCardType(val, item)"
            >
              <el-radio
                :label="val.value"
                v-for="val in item.options"
                :key="val.value"
                >{{ val.label }}</el-radio
              >
            </el-radio-group>
            <span>{{ item.default }}</span>
            <span v-if="edit === 'view' && item.type !== 'upload'">{{
              form[item.prop]
            }}</span>
            <!-- <img v-else :src="form[item.prop]" /> -->
            <el-image
              v-if="
                edit === 'view' && item.type === 'upload' && form[item.prop]
              "
              style="width: 1rem; height: 1rem"
              :src="form[item.prop]"
              :z-index="20000"
              :preview-src-list="[form[item.prop]]"
            >
            </el-image>
          </el-form-item>
        </template>
      </el-form>
      <slot name="desc"></slot>
      <div style="text-align: center">
        <el-button v-if="edit !== 'view'" type="primary" @click="submitForm"
          >提交</el-button
        >
        <el-button @click="isClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "tablePage",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    // 表单配置
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 初始表单数据
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // edit是否是编辑状态
    edit: {
      type: String,
      default: "add",
    },
  },
  watch: {
    params: {
      immediate: true,
      handler: function (val) {
        console.log(val);
        this.form = val;
      },
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      form: {},
      show: false,
      column: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleAvatarSuccess(res, prop) {
      this.form[prop] = res.data.url;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$notice({
          message: "上传头像图片只能是 JPG/PNG 格式!",
        }).isShow();
      }
      if (!isLt2M) {
        this.$notice({
          message: "上传头像图片大小不能超过 2MB!",
        }).isShow();
      }
      return isJPG && isLt2M;
    },
    submitForm() {
      const _val = "comForm";
      const self = this;
      // eslint-disable-next-line consistent-return
      return self.$refs[_val].validate((valid) => {
        if (valid) {
          this.$emit("onSubmit", self.form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    isOpen() {
      this.show = true;
    },
    isClose() {
      this.show = false;
      this.$emit("close", "");
      this.$refs.comForm.clearValidate();
    },
    changeCardType(val, item) {
      if (item.prop === "idCardType") {
        this.$emit("changeCardType", val);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.dialog_block {
  .el-dialog {
    margin-top: 10vh !important;
    border: none !important;
    width: 550px !important;
    border-radius: 10px !important;
  }
  .el-dialog__header {
    width: 550px;
    height: 50px;
    background: #cb1b32;
    padding: 0 !important;
    text-align: center;
    line-height: 50px;
    border-radius: 10px 10px 0 0;
    span {
      font-weight: bold;
      color: #ffffff !important;
      font-size: 18px;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #ffffff !important;
    }
  }
  .el-form-item {
    margin-bottom: 22px;
  }
  .el-form {
    padding: 0 !important;
    padding-right: 50px !important;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
/deep/ .el-button--primary {
  width: 100px;
  height: 38px;
  background: linear-gradient(232deg, #cb1930, #ff4054);
  box-shadow: 0px 3px 6px 0px rgba(255, 34, 67, 0.33);
  border-radius: 6px;
  border: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-right: 20px;
}
/deep/ .el-button--default {
  width: 100px;
  height: 38px;
  border: 1px solid #cc1930;
  border-radius: 6px;
  background: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #cc1930;
}
/deep/ .el-button--primary:hover {
  background: linear-gradient(-232deg, #ff7b80, #dc6166);
  box-shadow: 0px 3px 6px 0px rgba(255, 34, 67, 0.33);
}
/deep/ .el-button--default:hover {
  border: 1px solid #f13131;
  color: #f13131;
}
</style>