<template>
  <div class="integral_list_page">
    <myTable title="老师积分日志" :list="list" :columns="columns">
      <template slot="btns" slot-scope="item">
        <div class="table_btns">
          <div class="table_btn" @click="handleView(item.data.row)">
            查看
          </div>
        </div>
      </template>
    </myTable>
    <comdialog :list="fromList" ref="comdialog" :params.sync="stuInfo" :edit="alertStatus" title="ceshi" @onSubmit="onSubmit" @close="comdialogClose"></comdialog>
  </div>
</template>

<script>
  import myTable from '@/components/admin/table.vue'
  import comdialog from '@/components/admin/editDialog.vue'
  export default {
    name: 'headerSchool',
    components: {
      comdialog,
      myTable
    },
    data() {
      return {
        columns: [
        {
          prop: 'SC',
          label: '上课总积分',
        },
        {
          prop: 'CT',
          label: '测试总积分',
        },
        {
          prop: 'SSOTWC',
          label: '评分总积分',
        },
        {
          prop: 'PASC',
          label: '配课总积分',
        },
        {
          prop: 'BJBG',
          label: '班级测试报告积分',
        },
        {
          prop: 'CSBG',
          label: '学生测试报告总积分',
        },
        {
          prop: 'total',
          label: '总积分',
        }],
        list: [],
      }
    },
    created() {},
    mounted() {
      this.init()
    },
    methods: {
      //初始化
      init() {
        this.getTeacherInfo()
      },
      getTeacherInfo() {
        let data = {
          userId: this.$route.query.userId,
        }
        this.list = []
        this.api.admin.findTeacherRankingInfo(data).then((res) => {
          console.log("积分",res.data);
          res.data.id = this.$route.query.id
          this.list.push(res.data)
        })
      },

      
      handleView(val) {
        this.$router.push({
          path: '/admin/integralDetailList',
          query: {
            types: 'teacher',
            userId:this.$route.query.userId,
            ...val
          }
        })
      }
    },
  }
</script>

<style lang="scss">
  .integral_list_page {
    .table_btns {
      .el-input__inner {
        height: 60px;
        width: 250px;
        font-size: 20px;
      }
    }
  }
</style>